
import Home from "./home/Home";

const AdminView = (props) => {


  return (
    <div>
      {/* Home Page */}
      <Home />
    </div>
  );
};

export default AdminView;
