import React, { useEffect, useState, useContext } from 'react';
import { SiConvertio } from "react-icons/si";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { AuthUserContext } from "../../conext";
import { swapAddress, swapAbi } from '../../contract/swapContract';
import { tokenAddress, tokenAbi } from '../../contract/tokenContract';
import { usdtTokenAddres, usdtTokenAbi, usdtTokenAddress } from '../../contract/usdtContract';
import Web3 from "web3";

export default function Swap() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const web3 = new Web3(window.ethereum);
  const [amount, setAmount] = useState('');
  const [currencyFrom, setCurrencyFrom] = useState('BNB');
  const [currencyTo, setCurrencyTo] = useState('R2PIP');
  const [amountFrom, setAmountFrom] = useState('');
  const [amountTo, setAmountTo] = useState('');
  const [tokenBalance, setTokenBalance] = useState(0);
  const [usdtBalance, setUsdtBalance] = useState(0);
  const [tokenApproved, setTokenApproved] = useState(false);
  const [usdtApproved, setUsdtApproved] = useState(false);
  const [showApprove, setShowApprove] = useState(false);
  const [loading, setLoading] = useState(false);

  const tokenRate = 10000;
  const usdtRate = 500;
  const { walletAddress } = useContext(AuthUserContext);

  const options = () => {
    const option = `${currencyFrom}-${currencyTo}`;
    switch (option) {
      case 'BNB-R2PIP':
        return [tokenRate, bnb2Token];
      case 'BNB-USDT':
        return [usdtRate, bnb2Usdt];
      case 'R2PIP-BNB':
        return [1 / tokenRate, tokenApproved ?  token2BNB : tokenApprove];
      case 'R2PIP-USDT':
        return [usdtRate / tokenRate, tokenApproved ?  token2Usdt : tokenApprove];
      case 'USDT-BNB':
        return [1 / usdtRate, usdtApproved ? usdt2BNB : usdtApprove];
      case 'USDT-R2PIP':
        return [tokenRate / usdtRate, usdtApproved ? usdt2Token : usdtApprove];
      default:
        return;
    }
  }

  const getSwapContract = () => {
    const swapContract = new web3.eth.Contract(
      swapAbi,
      swapAddress
    );
    return swapContract;
  };

  const getTokenContract = () => {
    const tokenContract = new web3.eth.Contract(
      tokenAbi,
      tokenAddress
    );
    return tokenContract;
  };

  const getUsdtContract = () => {
    const usdtContract = new web3.eth.Contract(
      usdtTokenAbi,
      usdtTokenAddress
    );
    return usdtContract;
  };

  useEffect(() => {
    const getTokensInfo = async () => {
      if (!walletAddress) return;
      const tokenContract = getTokenContract();
      const usdtContract = getUsdtContract();
      const token = await tokenContract.methods.balanceOf(walletAddress).call();
      setTokenBalance(Number(token));
      const usdt = await usdtContract.methods.balanceOf(walletAddress).call();
      setUsdtBalance(Number(usdt));

      const tokenAllowance = await tokenContract.methods.allowance(walletAddress, swapAddress).call();
      const usdtAllowance = await usdtContract.methods.allowance(walletAddress, swapAddress).call();

      setTokenApproved(token <= tokenAllowance);
      setUsdtApproved(usdt <= usdtAllowance);
    }
    
    getTokensInfo();
    setAmountFrom('');
    setAmountTo('');
  }, [walletAddress]);

  const handleSwap = () => {
    // Swap currencies
    const tempCurrency = currencyFrom;
    setCurrencyFrom(currencyTo);
    setCurrencyTo(tempCurrency);

    // Swap amounts
    const tempAmount = amountFrom;
    setAmountFrom(amountTo);
    setAmountTo(tempAmount);
  };

  const handleChangeCurrency = (value, fromTo) => {
    setAmountFrom('');
    setAmountTo('');
    let inputValue = value;
    if (fromTo) {
      if (currencyFrom === value) {
        setCurrencyFrom(currencyTo);
        inputValue = currencyTo;
      }
      setCurrencyTo(value);
    } else {
      if (currencyTo === value) setCurrencyTo(currencyFrom)
      setCurrencyFrom(value); 
    }

    if (inputValue === 'R2PIP' && !tokenApproved) {
      setShowApprove(true);
    }
    else if (inputValue === 'USDT' && !usdtApproved) {
      setShowApprove(true);
    } else {
      setShowApprove(false);
    }
  }

  const handleAmountChange = (value, fromTo) => {
    if (fromTo) {
      const [rate] = options()
      setAmountFrom(value / rate)
      setAmountTo(value)
    } else {
      const [rate] = options()
      setAmountFrom(value)
      setAmountTo(value * rate)
    }
  }

  const tokenApprove = async () => {
    const tokenContract = getTokenContract();
    await tokenContract.methods.approve(swapAddress, tokenBalance).send({ from:walletAddress, type: 0 });
  }

  const usdtApprove = async () => {
    const usdtContract = getUsdtContract();
    await usdtContract.methods.approve(swapAddress, tokenBalance).send({ from:walletAddress, type: 0 });
  }

  const bnb2Token = async (bnbValue) => {
    const swapContract = getSwapContract();
    return (
      await swapContract.methods
        .buyTokensWithBNB()
        .send({ from: walletAddress, value: bnbValue, type: 0 })
    )
  }

  const bnb2Usdt = async (bnbValue) => {
    const swapContract = getSwapContract();
    return (
      await swapContract.methods
        .buyUSDTWithBNB()
        .send({ from: walletAddress, value: bnbValue, type: 0 })
    )
  }

  const token2BNB = async (tokens) => {
    const swapContract = getSwapContract();
    return (
      await swapContract.methods
        .buyBNBWithToken(tokens)
        .send({ from: walletAddress, type: 0 })
    )
  }
  const token2Usdt = async (tokens) => {
    const swapContract = getSwapContract();
    return (
      await swapContract.methods
        .buyUSDTWithToken(tokens)
        .send({ from: walletAddress, type: 0 })
    )
  }
  const usdt2BNB = async (usdt) => {
    const swapContract = getSwapContract();
    return (
      await swapContract.methods
        .buyBNBWithUSDT(usdt)
        .send({ from: walletAddress, type: 0 })
    )
  }
  const usdt2Token = async (usdt) => {
    const swapContract = getSwapContract();
    return (
      await swapContract.methods
        .buyTokensWithUSDT(usdt)
        .send({ from: walletAddress, type: 0 })
    )
  }

  const handleSwapAction = async () => {
    try {
      setLoading(true);
      const value = Number(amountFrom);
      if (!value) return;
      const etherValue = web3.utils.toWei(value, 'ether');
      const [_, method] = options();
      const result = await method(etherValue);
    }
    catch (error) {
      console.log("swap error: ", error)
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <div className='flex flex-col items-center my-5 justify-center min-h-screen h-full mx-1'>
      <h1 className="flex justify-center items-center text-[30px] md:text-[40px] lg:text-[50px] w-full font-extrabold text-red1 mb-2 md:mb-5">
        Swap Your Currency
      </h1>
      <div className='bg-gray-900 shadow-lg rounded-2xl p-8 mt-6 w-full max-w-md'>
        <div className='text-center mb-6'>
          <h2 className='text-2xl font-bold text-red1 mb-2'>Currency Swap</h2>
          <p className='text-gray-300'>Select the amount and currencies to swap</p>
        </div>

        <div className='flex flex-col gap-4 items-center justify-between mb-6'>
          {/* First select */}
          <select
            value={currencyFrom}
            onChange={(e) => handleChangeCurrency(e.target.value, 0)}
            className='border border-gray-300 rounded-lg w-full text-gray-400 bg-transparent p-3 focus:outline-none'
          >
            <option value='BNB'>BNB</option>
            <option value='R2PIP'>R2PIP</option>
            <option value='USDT'>USDT</option>
          </select>

          {/* First input field */}
          <input
            type='number'
            value={amountFrom}
            onChange={(e) => handleAmountChange(e.target.value, 0)}
            className='rounded-lg p-3 w-full text-gray-300 bg-transparent border focus:outline-none'
            placeholder='0.0'
          />

          {/* Arrow icon */}
          <div onClick={handleSwap} className="cursor-pointer">
            <SiConvertio size={50} className='text-red1' />
          </div>

          {/* Second select */}
          <select
            value={currencyTo}
            onChange={(e) => handleChangeCurrency(e.target.value, 1)}
            className='border border-gray-300 rounded-lg w-full text-gray-400 bg-transparent p-3 focus:outline-none'
          >
            <option value='BNB'>BNB</option>
            <option value='R2PIP'>R2PIP</option>
            <option value='USDT'>USDT</option>
          </select>

          {/* Second input field */}
          <input
            type='number'
            value={amountTo}
            onChange={(e) => handleAmountChange(e.target.value, 1)}
            className='rounded-lg p-3 w-full text-gray-300 bg-transparent border focus:outline-none'
            placeholder='0.0'
          />
        </div>

        <button
          onClick={handleSwapAction}
          className='bg-red1 text-white font-bold py-3 rounded-lg w-full '
          disabled={loading}
        >
          {loading ? 'Loading...' : (showApprove ? 'Approve' : 'Swap Now')}
        </button>
      </div>
    </div>
  );
}
