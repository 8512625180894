import React from 'react'
import VoteTable from '../../components/vote/VoteTable'

export default function Vote() {
  return (
    <div>
      
      <VoteTable/>
    </div>
  )
}
